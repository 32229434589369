
(function($)
{
	"use strict";

	var normalizeTeasers = function()
	{
		$('.layout_latest').data('normalized', false);
		$('.layout_latest').each(function()
		{
			// get the teaser element
			var $teaser = $(this);

			// check if already normalized
			if ($teaser.data('normalized'))
			{
				return true;
			}

			// get siblings
			var $siblings = $teaser.siblings('.layout_latest').addBack();

			// get the max offset for image
			var maxOffset = 0;
			$siblings.css('padding-top',0);
			$siblings.find('figure').each(function()
			{
				var offset = $(this).position().top;
				if (offset > maxOffset && offset < 200)
				{
					maxOffset = offset;
				}
			});

			// process each sibling
			$siblings.each(function()
			{
				// get the sibling
				var $sibling = $(this);

				// set padding
				$sibling.css('padding-top', (maxOffset - $sibling.find('figure').position().top) + 'px');

				// set to normalized
				$sibling.data('normalized', true);
			});
		});
	};

	var containerMinHeight = function()
	{
		$('#container').css('min-height', ($(window).height() - $('#header').outerHeight() - $('#footer').outerHeight()) + 'px');
	};

	$(document).ready(function()
	{
		/**
		 * Smooth scrolling to anchor
		 */
		$('a[href*=\\#]').on('click', function(event)
		{
			if ($(this.hash).length > 0 )
			{
			    event.preventDefault();
			    $('html,body').animate({scrollTop:$(this.hash).offset().top}, 500);
			}
		});

		/**
		 * Normalize teasers
		 */
		normalizeTeasers();

		/**
		 * Surprise me more
		 */
		$('.surprise-me-more a').click(function(e)
		{
			e.preventDefault();
			$('.surprise-me-list .layout_latest').show();
			$(this).hide();
			return false;
		});

		/**
		 * Article filter
		 */
		$('.article-filter a').click(function(e)
		{
			e.preventDefault();
			var $a = $(this);
			$('body').addClass('loading');
			$.get($a.attr('href'), function(response)
			{
				$('#start-newslist').replaceWith($(response).find('#start-newslist'));
				normalizeTeasers();

			}).complete(function()
			{
				$('body').removeClass('loading');
			});
			return false;
		});

		/**
		 * #container min height
		 */
		containerMinHeight();

		/**
		 * Auto grow text areas
		 */
		$('textarea').each(function()
		{
			new Autogrow($(this).get(0));
		});
	});

	$(window).on('load', function()
	{
		/**
		 * Normalize teasers
		 */
		normalizeTeasers();
	});

	$(window).resize(function()
	{
		/**
		 * Normalize teasers
		 */
		normalizeTeasers();

		/**
		 * #container min height
		 */
		containerMinHeight();
	});

	$(window).scroll(function()
	{
		var windowHeight = $(window).height();
		var scrollPosTop = $(window).scrollTop();
		var scrollPosBottom = scrollPosTop + windowHeight;

		/**
		 * Parallax for teasers
		 */
		$('.layout_latest figure, .layout_full h1 + figure').each(function()
		{
			var $figure = $(this);
			var $img = $figure.find('img');
			var offset = $figure.offset().top;
			var factor = (scrollPosBottom - offset) / windowHeight;
			var range = $img.height() - $figure.outerHeight();
			var top = range * factor;
			$img.css('top',(-range+top)+'px');
		});

	});

})(jQuery);
